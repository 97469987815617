import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

export default (props) => (
  <div className="basic-process-area pt-30" style={{background: 'rgb(10, 29, 57)'}}>
    <div className="container">
      <div className="area-title text-center blue-title" style={{marginBottom: '80px'}}>
        <h2 style={{color: '#fff'}}>Services</h2>
      </div>
      <Row className="mb-30">
        <Col md={4} sm={12}>
          <div className="process-item text-center">
            <div className="process-item-icon" style={{paddingTop: '40px', paddingBottom: '40px'}}>
              <span className="icon-lightbulb"></span>
            </div>
            <div className="process-item-content" style={{position: 'inherit', paddingTop: '10px'}}>
              <span className="process-item-number" style={{height: '120px', width: '120px', borderRadius: '120px', background: 'rgb(10, 29, 57)'}}><i className="ion-finger-print-outline" style={{lineHeight: '110px', verticalAlign: 'middle', fontSize: '48px'}} /></span>
              <h3 className="process-item-title" style={{fontSize: '20px', color: '#fff'}}>Communications Strategy</h3>
              <p style={{marginBottom: '40px', color: '#fff'}}>Get clear on who you are, who you’re speaking to, what you need to say, and how to
say it.</p>
            </div>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="process-item text-center">
            <div className="process-item-icon" style={{paddingTop: '40px', paddingBottom: '40px'}}>
              <span className="icon-linegraph "></span>
            </div>
            <div className="process-item-content" style={{position: 'inherit', paddingTop: '10px'}}>
              <span className="process-item-number" style={{height: '120px', width: '120px', borderRadius: '120px', background: 'rgb(10, 29, 57)'}}><i className="ion-document" style={{lineHeight: '110px', verticalAlign: 'middle', fontSize: '48px'}} /></span>
              <h3 className="process-item-title" style={{fontSize: '20px', color: '#fff'}}>Communications Execution</h3>
              <p style={{marginBottom: '40px', color: '#fff'}}>From investor letters and marketing materials to web copy and voiceover scripts, make sure every stakeholder touchpoint brings your communications strategy to life.</p>
            </div>
          </div>
        </Col>
        <Col md={4} sm={12}>
          <div className="process-item text-center">
            <div className="process-item-icon" style={{paddingTop: '40px', paddingBottom: '40px'}}>
              <span className="icon-lightbulb"></span>
            </div>
            <div className="process-item-content" style={{position: 'inherit', paddingTop: '10px'}}>
              <span className="process-item-number" style={{height: '120px', width: '120px', borderRadius: '120px', background: 'rgb(10, 29, 57)'}}><i className="ion-ios-people-outline" style={{lineHeight: '110px', verticalAlign: 'middle', fontSize: '48px'}} /></span>
              <h3 className="process-item-title" style={{fontSize: '20px', color: '#fff'}}>Team Facilitation</h3>
              <p style={{marginBottom: '40px', color: '#fff'}}>Let someone else be your sword and shield—managing competing priorities, navigating group dynamics, aligning perspectives, and breaking the cycle of decision paralysis.</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </div>
)
