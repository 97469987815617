import React from 'react';
import Scroll from 'react-scroll';
import Hero from '../components/hero';
import Overview from '../components/overview';
import Services from '../components/services';
import Biography from '../components/biography';
import Testimonials from '../components/testimonials2';
import Contact from '../components/contact';
import ScrollButton from '../components/scrollButton';
import Layout from '../layouts';

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Hero />
        <Scroll.Element name="overview" />
        <Overview />
        <Scroll.Element name="services" />
        <Services />
        <Scroll.Element name="biography" />
        <Biography />
        <Scroll.Element name="testimonials" />
        <Testimonials />
        <Scroll.Element name="contact" />
        <Contact />
      </Layout>
    )
  }
}
