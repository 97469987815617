import React from 'react';
import axios from 'axios';

export default class Component extends React.Component {
  state = {
    status: 'ready',
    name: '',
    email: '',
    message: ''
  }

  submit = (e) => {
    e.preventDefault();
    this.setState({status: 'sending'});
    const data = ({
      name: this.state.name,
      email: this.state.email,
      message: this.state.message,
    });
    fetch('https://013307bf7b38b4800df0f8b82803ed41.m.pipedream.net', {
      body: JSON.stringify(data),
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/json'
      },
      method: 'POST', mode: 'cors', redirect: 'follow', referrer: 'no-referrer',
    }).then(res => {
      this.setState({
        status: 'sent',
        email: '',
        name: '',
        message: ''
      })
    }).catch((err) => {this.setState({status: 'error'})});
  }

  render() {
    return (
  <div className="basic-contact-form ptb-60">
  			<div className="container">
  				<div className="area-title text-center">
  					<h2>Let’s talk</h2>
  					<p>For more information, pricing, or portfolio samples, please contact Michelle directly.</p>
  				</div>
  				<div className="row">
  					<div className="col-sm-8 col-sm-offset-2">
  						<form id="contact-form" onSubmit={this.submit}>
  							<div className="row">
  								<div className="col-md-6 form-group">
  									<label className="sr-only">Name</label>
  									<input type="text" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})} className="form-control input-lg" placeholder="Name" />
  									<p className="help-block text-danger"></p>
  								</div>
  								<div className="col-md-6 form-group">
  									<label className="sr-only">Email</label>
  									<input type="email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} className="form-control input-lg" placeholder="Email" />
  									<p className="help-block text-danger"></p>
  								</div>
  								<div className="col-md-12 form-group">
  									<textarea value={this.state.message} onChange={(e) => this.setState({message: e.target.value})} className="form-control input-lg" rows="7" placeholder="Message"></textarea>
  									<p className="help-block text-danger"></p>
  								</div>
  								<div className="col-md-12 text-center">
                    <button style={{background: 'rgb(10,29,57)'}} type="submit" disabled={this.state.status == 'sending'} className="btn btn-lg btn-round btn-dark">Send</button>
  								</div>
  							</div>
  						</form>
              {this.state.status == 'sent' ? <h3 style={{color: 'green', textAlign: 'center', marginTop: '30px'}}>Message successfully sent!</h3> : null }
              {this.state.status == 'error' ? <h3 style={{color: 'red', textAlign: 'center', marginTop: '30px'}}>Sorry there was an error :-(</h3> : null }
  					</div>
  				</div>
  			</div>
  		</div>
    )
  }
}
