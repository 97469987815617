import React from 'react';
import HeroImage from '../img/hero-bg.jpg'
import Demiguise from 'react-demiguise';

export default (props) => (
  <div className="breadcrumb-2-area pos-relative bg-2 bg-black-alfa-40" style={{background: `url(${HeroImage})`, maxHeight: '600px', height: '90vh'}}>
    <div className="hero-caption">
      <div className="hero-text">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h1 className="text-uppercase color-white breadcrumb-2" style={{fontFamily: 'Amatic SC, cursive', fontSize: '86px', fontWeight: 700, textAlign: 'center'}}>Find The Words</h1>
              <p className="lead color-white " style={{fontFamily: 'Amatic SC, cursive', fontSize: '64px', textTransform: 'uppercase', fontWeight: 700}}>
                <Demiguise
                  messages={[
                    'to inform',
                    'to influence',
                    'to inspire',
                    'to impress',
                  ]}
                  delay={400}
                  loop
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)
