import React from 'react';
import MichelleImage from '../img/michelle.jpg'

export default (props) => (
  <div className="about-us-area pt-30 pb-30">
    <div className="container">
      <div className="area-title text-center" style={{marginBottom: 0}}>
        <h2>About Michelle</h2>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="about-text mt-30">
            <p>In her work as a storyteller, writer, and communications strategy consultant, Michelle Antle works with everyone from corporate executives to civic leaders, startups to foundations, investment funds to nonprofits. Prior to founding Find the Words, Michelle spent nearly a decade working with Fortune 100 senior leaders to create, articulate, and socialize new products and strategic initiatives.</p>
            <p>Michelle is a graduate of the Wharton School at the University of Pennsylvania with concentrations in Marketing and Psychology. Born and raised in New Orleans, Michelle now lives in Oakland with her husband and two young children.</p>
            <a href="https://www.linkedin.com/in/michelle-antle-8848978/" target="_blank"><i className="ion-social-linkedin-outline" style={{fontSize: '36px', color: '#007bb6'}}/></a>
          </div>
        </div>
        <div className="col-md-6">
          <div className="about-img hidden-sm hidden-xs" style={{textAlign: 'center'}}>
            <img src={MichelleImage} alt="" style={{height: '350px', widht: '350px', borderRadius: '50%'}} />
          </div>
        </div>
      </div>
    </div>
  </div>
)
