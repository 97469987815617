import React from 'react';
import Scroll from 'react-scroll';

export default class ScrollButton extends React.Component {
  state = {show: false}

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    this.setState({show: window.scrollY ? true : false})
  }

  render() {
    return this.state.show ? (
      <a id="scrollUp" href="#"
        onClick={(e) => {e.preventDefault(); Scroll.animateScroll.scrollToTop()}}
        style={{position: 'fixed', zIndex: '2147483647', bottom: 0}}><i className="chevron-up-outline"></i>
      </a>
    ) : null
  }
}
