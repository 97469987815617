import React from 'react';
import { Carousel } from 'react-bootstrap';
import BackgroundImage from '../img/testimonial-bg.jpg'

export default (props) => (
  <div className="basic-testimonial-area bg-black-alfa-40 bg-5 ptb-110" style={{background: `url(${BackgroundImage})`, padding: 0}}>
      <div className="row">
        <div className="testimonial-active owl-carousel" style={{marginLeft: '10px', marginRight: '10px'}} >
        <Carousel interval={8000} pauseOnHover={true} style={{paddingBottom: '30px', paddingTop: '60px', height: '385px'}}>
          {Testimonials.map((testimonial) => {
            return (
              <Carousel.Item>
                <div className="testimonial-box">
                  <blockquote>
                    {testimonial.quote}
                  </blockquote>
                  <div className="testimonial-content" style={{textAlign: 'left', marginBottom: '30px'}}>
                    <h6 className="testimonial-name">{testimonial.name}</h6>
                    <span className="testimonial-pos">{testimonial.title}</span>
                  </div>
                </div>
              </Carousel.Item>
            )
          })}
          </Carousel>
        </div>
      </div>
  </div>
)

const Testimonials = [
  {
    name: 'Rick Rees',
    title: 'Co-Founder and Managing Partner, LongueVue Capital',
    quote: "We have leveraged FTW to enhance our communication strategy, and we have been extraordinarily impressed with Michelle’s ability to quickly learn our business, adapt to our style, and add immediate value. FTW provides attentive, senior level service and enables our team to spend more time on investing activities while still delivering timely, consistent, and high-quality communications to our stakeholders.",
  },
  {
    name: 'Martha Landrum',
    title: 'VP of Marketing and Communications, Greater New Orleans Foundation',
    quote: "Michelle turns words into music. She has a sixth sense for messaging that is powerful and spot-on. As our Foundation’s go-to speech and copy writer across many different initiatives, Michelle gets it right on the first try every time. I’ve never had to ask for a second version!",
  },
  {
    name: 'Heidi Raines',
    title: 'President and CEO, Compliance Partners',
    quote: 'Michelle is not only an outstanding and versatile writer, but also an experienced marketing and business development strategist—a rare and valuable combination. She asks intelligent questions, meets or exceeds deadlines, and always creates the right blend of analytical detail and narrative. Whether I need creative ideas, project execution, editorial review, or compelling copy, I turn to Michelle time and time again.'
  },
  {
    name: 'Esther Rogers',
    title: 'Co-Head, Idea Couture Publishing',
    quote: "Regardless of the topic, Michelle knows how to craft content that is not only engaging and entertaining, but also leaves a long-term impact. You’ll be thinking about her words long after you stop reading.",
  },
  {
    name: 'Leslie Jacobs',
    title: 'Founder, Gateway Collaborative',
    quote: "Michelle did an excellent job leading our communications strategy development from start to finish, including understanding and reconciling the differing needs of the multiple organizations in our Collaborative. She did her homework, managed the room with finesse, uncovered the heart of our communication issues, and framed a strategy that allowed us to move forward with confidence and clarity.",
  },
  {
    name: 'Martha Murphy',
    title: 'Board Member, Ellis Marsalis Center for Music',
    quote: "Michelle's creative and expressive writing has set a new standard for our nonprofit's fundraising and PR efforts. Her wordsmithing should be called word art. I became an instant rock star for introducing Michelle to our organization - I'm still basking in the enthusiastic kudos of my team!",
  },
]
