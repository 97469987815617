import React from 'react';

export default (props) => (
  <div className="about-us-area pt-30 pb-30">
    <div className="container">
      <div className="area-title text-center" style={{marginBottom: '50px', width: '70%'}}>
        <h2>WORDS ARE POWER</h2>
        <p style={{marginBottom: '30px', lineHeight: '28px'}}>Words are one of the most powerful tools at our disposal. They can move people to action and inspire change. They can garner buy-in for new ideas and demonstrate leadership or expertise. They help us tell our stories and forge human connections.</p>
        <p style={{marginBottom: '30px', lineHeight: '28px'}}><strong>As a specialist in strategic, high-impact communications, Michelle Antle is passionate about the power and eloquence of both the spoken and written word. She works with clients to find their voice, hone their strategy, and craft custom messaging that informs, influences, inspires, and impresses audiences.</strong></p>
        <p style={{marginBottom: '30px', lineHeight: '28px'}}>Whatever your story, Michelle will help you find the words to tell the most resonant and compelling version.</p>
      </div>
    </div>
  </div>
)
